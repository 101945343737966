@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Alegreya:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


html {
  scroll-behavior: smooth;
  scroll-padding-top: 65px;
}

body {
  overflow-x: hidden;
}


body
{
  font-family: "Roboto Condensed", sans-serif!important;
  font-weight: normal!important;
    font-style: normal!important;
    overflow-x: hidden;

}

.text-center
{
  text-align: center;
}


.shrink {
  height: 50px; /* Réduire la hauteur selon vos préférences */
  transition: height 0.3s; /* Ajouter une transition pour une animation fluide */
}

.logo-nb
{
  height: 35px;
  width: 35px;
  transition: transform 0.3s;
}

.logo-nb:hover
{
  height: 35px;
  width: 35px;
  transform: scale(1.05);
}


.navbar
{box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.553);
  /* font-family: 'Mukta', sans-serif!important;
  letter-spacing: 2px;
  font-size: 18px; */
  flex-direction: row;
  font-family: "Alegreya Sans SC", sans-serif;
  letter-spacing: 2px;
        font-weight: 600;
        font-size: 20px;
        background-color: rgb(19 19 19) !important;

}

.navbar-dark, .navbar[data-bs-theme=dark]
{background-color: #000 !important;}

.nav-link
{
  color: white!important;
}

.nav-link:hover
{
  color: rgb(214, 94, 89)!important;
}

.navbar-brand
{
  max-width: 91px;
  max-height: 60px;
}

.style-navbar
{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 50px;

}

.left-navbar
{
  margin-left: auto;
}

.nav-link 
{
  margin-right: 12px; 
}

.logo-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-nb {
  margin-right: -15px; /* Ajustez la marge à votre convenance */
}

.dropdown-item:active
{
  background-color: rgb(214, 94, 89)!important;
}

.logo-rido
{
  max-width: 100%;
  max-height: 50px;
}

.navbar-expand-lg .navbar-collapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Banner */

.banner-container
{

}

.back-img
{
  margin-top: 60px;
  background-size: cover;
  background-position: center;
  min-height: calc( 100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-image 1s ease-in-out;
  position: relative;
  color: #ffffff;
  overflow-x: hidden;
}


.over-banner
{
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.574), rgb(0, 0, 0)); 
  min-height : 100vh;
  width: 100vw ;


}
.info-banner
{
  padding-left: 70px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 2%;
  padding-top: 2%;
    padding-right: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.info-banner h1, .info-banner h2, .info-banner h3
{
 
  /* -webkit-text-stroke: 1px black; 
  text-stroke: 1px black; */
  text-shadow : 0px 0px 10px rgba(0,0,0,.2);
  font-weight: 600;
  font-family: "Alegreya Sans SC", sans-serif;
}

.info-banner h5
{
  margin-top: 5px;

  font-family: "Alegreya Sans SC", sans-serif;
  font-style: italic;
}

.info-banner h2
{

  font-size: 25px;

}

.info-banner h3
{
  font-size: 22px;
  width: 100vw;
}

.banner-2 h2
{
  margin-bottom: 10px;
}

.banner-1
{
  background-color: #000;
  color: white;
  font-family: "Alegreya Sans SC", sans-serif;
  text-align: center;
  min-height: 170px;
  display: flex; 
  align-items: center; 
  justify-content: start;
  flex-direction: column;
}

@media (max-width: 600px) {
  .banner-1 {
    height: 250px;
    align-items: center;
    justify-content: start;

  }
}

/* Music */

.music-container
{
  margin-top: 30px;
  padding: 30px;
  /* margin-left: 12%;
  margin-right: 12%; */
  margin-bottom: 30px;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
}

.music-container .music-titre
{
  font-family: "Poppins", sans-serif;
  font-size: 30px;
}


.music-container-other {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.music-logo-container *
{

}

.logo-icon
{
  height: 80px;
width: 80px;
transition: transform 0.2s ease-in-out;
margin : 0px 20px;
}


@media (max-width: 768px) {
  .music-container {
    margin: 10px; /* Réduire les marges à 10px de tous les côtés */
    padding: 10px; /* Réduire le rembourrage à 10px de tous les côtés */
  }
  .logo-icon
{
  height: 70px;
width: 70px;
transition: transform 0.2s ease-in-out;
margin : 0px 5px;
}
}




.amazon-logo
{
  border-radius: 16px;
}

.logo-icon:hover {
  transform: scale(1.05); /* Agrandit l'image de 10% lorsqu'on passe la souris */
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.music-titre
{
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 40px;
  font-family: "Alegreya Sans SC", sans-serif;
}

.artists-titre
{
  font-weight: 600;
  font-family: "Alegreya Sans SC", sans-serif;
}

/* Albums */

.albums-container
{
  padding: 30px;
  /* background-color: rgb(22, 22, 22); */
  overflow-x: hidden;
  /* background-color: rgb(34, 34, 34); */
  background-color: #333;

}

.albums-container h2
{
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 600;
}

.albums-container .row
{
  /* margin-left: 6%;
  margin-right: 6%; */

}

.bouton-rectangle-container {
  display: flex;
  justify-content: center; /* Aligner horizontalement au centre */
  align-items: center; /* Aligner verticalement au centre */
}

.bouton-rectangle {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  color: white;
  border: 2px solid white;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Ajoutez la transition pour la bordure */
  margin: 10px;
  font-family: 'Mukta', sans-serif!important;
  letter-spacing: 2px;
}

.bouton-rectangle:hover {
  background-color : rgb(214, 94, 89);
  color: white;
  border-color: rgb(214, 94, 89);
}

.albums-titre
{
  margin-bottom: 30px;
  color: #ffffff;
}

/* Styles pour les boutons lorsque l'écran est réduit */
@media (max-width: 768px) {
  .bouton-rectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
}

.albums-container img
{
  height: 320px;
  width: 320px;
}

.all-albums-container
{
  max-width: 1100px;
  /* display: flex; */
  margin: auto;
}

/* Albums All */
.albums-all-container
{
  padding-bottom: 50px;
}

.albums-all-container img
{
  height: 270px;
  width: 270px;
}

.albums-all
{
  margin-left: 8%!important ;
  margin-right: 8%!important;
}

.all-music-container *
{

}



/* Artists */

.container-artists
{
  padding: 30px;
}

.imgContainer {
  animation: updown 9s linear infinite; 
   width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  overflow: hidden;
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.634);
  margin-bottom: 30px; 

}

.imgContainer-projects {
  /* animation: updown 9s linear infinite; */
  width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  overflow: hidden;
  transition: transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Ajoute l'ombre */
  margin-bottom: 30px;
  margin: 5px;
}

.overlay {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 45, 45, 0.732);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center; */
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(45, 45, 45, 0.732);
  opacity: 0;
  transition: opacity 0.3s, height 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-overlay > .title-overlay
{

  font-weight: bold;
  font-size: 22px;
  text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333;
}

.text-overlay > .title-banner-overlay
{

  font-weight: bold;
  font-size: 25px;
  
}


.text-overlay > .desc-overlay
{
  font-style: italic;
  text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333;
  /* padding: 5px; */
}

.text-overlay {
  color: #ffffff;;
  font-size: 18px;
  text-align: center;

}

.imgContainer:hover {
  transform: scale(1.01);
  cursor: pointer;
} 

.imgContainer:hover .overlay {
  opacity: 1;
  height: 100%;
}

.imgContainer-projects:hover {
  transform: scale(1.01);
  cursor: pointer;
} 

.imgContainer-projects:hover .overlay {
  opacity: 1;
  height: 100%;
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.container-artists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.artists
{
  padding: 30px;
}

.artists a
{
  text-decoration: none;
  color: #333;
}

.artist-card {
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  max-width: 269px;
  transition: background-color 0.3s;
}

@media (max-width: 700px) {
  .artist-card {
    max-width: 150px;
  }
  .container-artists {
    padding: 30px 0px;
}
}

.artist-card:hover {
  background-color: rgb(214, 94, 89);
}

.last-in-row {
  margin-right: 0;
}

.artist-image {
  max-width: 100%;
  height: auto;
}

.artist-name {
  padding: 10px;
  font-weight: bold;

}



/* Footer */

/* Ajoutez ces styles CSS à votre feuille de style CSS */
.footer {
  background-color: rgb(214, 94, 89);
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 13px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 2px;
}


.footer a{

  color: white;
  text-decoration: underline;
  

}
.footer a:hover{

  color: white;
  text-decoration: underline;

}

.footerDiv {
  /* background-color: rgba(233, 212, 199, 0.548);
  padding: 10px; */
  /* display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: left;
  margin-left: 30px; */

 padding: 10px 10px ;
text-align: center;
}

.maissa-dev
{
  white-space: nowrap;
}

.footer-right {
  /* background-color: rgba(233, 212, 199, 0.548);
  padding: 10px; */
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: right;
}

.footer-right .logo-nb
{
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 400px) {
  
}

/* Alliances */

.container-alliances {
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  padding-bottom: 30px;
  overflow-x: hidden;
}

.alliances-img {
  width: 500px;
  height: auto;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.634);
  transition: transform 0.3s, box-shadow 0.3s;
}


.alliances-img-banner
{
  width: 500px;
  height: auto;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.634);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 40px;
}

.alliances-img-banner:hover
{
  transform: scale(1.01);
}



.imgContainerAlliances {
  animation: updown 9s linear infinite; 
   width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  overflow: hidden;
  transition: transform 0.6s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.634);
  margin-bottom: 30px; 
}

.overlayAlliances {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(45, 45, 45, 0.484);
  opacity: 0;
  transition: opacity 0.3s, height 0.3s; /* Utiliser height au lieu de transform */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.imgContainerAlliances:hover {
  transform: scale(1.01);
  cursor: pointer;
} 

.imgContainerAlliances:hover .overlayAlliances {
  opacity: 1;
}

/* Banner */

.banner-titre {
  font-family: 'Poppins';
  background: linear-gradient(

    rgba(0, 0, 0, 0.536), 

    rgba(0, 0, 0, 0.536)

  ),url(./assets/img/alliances-2.jpg)no-repeat;background-size:cover;
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: left top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centre le contenu verticalement */
  height: 320px;
  color: white;
  margin-top: 60px;
}

.banner-titre h1,
.banner-titre h2 {
  text-align: center; /* Centre le contenu horizontalement */
  text-shadow : 0px 0px 10px rgb(0, 0, 0);
}

.banner-titre h1
{
  font-size: 52px;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: "Alegreya Sans SC", sans-serif;
}

.banner-titre h2
{
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 2px;
  padding-left: 12%;
  padding-right: 12%;
}

/* Evenements */

.evenement-container
{
  /* background-image: url('./assets/img/alliances-2.jpg'); */
  background: linear-gradient(

    rgba(0, 0, 0, 0.426), 

    rgba(0, 0, 0, 0.296)

  ),url(./assets/img/alliances-2.jpg)no-repeat;background-size:cover;
  padding: 50px;
  margin-bottom: 40px;
}

.evenement-container h2, .evenement-container h5
{
 
  text-align: left!important;
  color: white;

}

.evenement-container h2
{
  padding-top: 30px; 
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 2px;
  font-family: "Alegreya Sans SC", sans-serif;
}
.evenement-container h5
{
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 25px;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
}


.table-style {
  opacity: 0.95; /* Ajustez la valeur d'opacité selon vos préférences (0.0 pour transparent, 1.0 pour opaque) */
}

.table-shadow {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* Ajoute une ombre légère */
  border-radius: 8px;
}

.centered-cell {
 text-align: center;
 font-family: 'Poppins';
}

th
{
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
}

.events-container
{
  padding: 5%;
  height: calc(100vh - 580px);

}

/* Bio */

.bio-container
{
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 3%;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1.7px;
}


.bio-container h3
{
  font-weight: 700!important;
  font-size: 30px;
}

.bio-container img {
  height: 35%;
  width: 35%;
  margin-top: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.059);
  float: left; /* Ajoutez cette ligne pour faire flotter l'image à gauche */
  margin-right: 20px; /* Ajoutez un espace à droite de l'image pour séparer le texte */
}

.bio-text
{
  margin-top: 70px;
}

.bio-button
{
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
  font-size: 15px;
  display: inline-block;
  margin-right: auto;
}

/* Contact */

.single-container
{
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;

}

/* Ciblez les éléments input dans le formulaire */
.input-form, .text-form {
  border: 1px solid #333!important; /* Définit une bordure noire de 2px */
  border-radius: 0!important; /* Supprime le bord arrondi */
}


.input-rectangle {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Ajoutez la transition pour la bordure */
  margin: 10px;
  font-family: 'Mukta', sans-serif!important;
  letter-spacing: 2px;
}

.input-rectangle:hover {
  background-color : rgb(214, 94, 89);
  color: white;
  border-color: rgb(214, 94, 89);
}

.info-contact-container
{
  margin-right: 3%;
  padding-bottom: 30px;
}

.info-contact-col h5
{
  font-weight: 700;
  font-size: 30px;
  padding-left: 30px;
}


.info-contact-col p
{
  margin-right: 3%;
  font-size: 15px;
  padding-left: 30px;
}


.music-info-container
{
  margin-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 100px;
}

.music-info-container .col-1
{
display: flex;
    align-items: center;
    justify-content: center;
}

.alliancesimg
{
  max-width: 300px;
  max-height: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.634);
  transition: transform 0.3s, box-shadow 0.3s;
}


.bouton-rectangle-alliances {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  color: rgb(214, 94, 89);
  border: 1.5px solid rgb(214, 94, 89);
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Ajoutez la transition pour la bordure */
  margin-right: 10px;
  font-family: 'Mukta', sans-serif!important;
  letter-spacing: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bouton-rectangle-alliances:hover {
  background-color : rgb(214, 94, 89);
  color: white;
  border-color: rgb(214, 94, 89);
}


.info-alliances
{
  margin-top: 30px!important;
}

.music-spotify
{
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.music-spotify .music-titre
{
  font-size: 30px;
}

.achat-stripe
{
  border-radius: 12px; /* Définit le rayon de la bordure arrondie */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Ajoute une ombre */
}


.bouton-rectangle-alliances-d {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  color: white;
  border: 1px solid #0074d4;
  background-color: #0074d4;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Ajoutez la transition pour la bordure */
  margin-right: 10px;
  font-family: 'Mukta', sans-serif!important;
  letter-spacing: 2px;
}

.bouton-rectangle-alliances-d:hover {
  background-color : #0c2c47;
  color: white;
  border-color: #0c2c47;
}

.album-info
{
  font-size: 17px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}


@media (max-width: 480px) {
  .music-spotify .text-center:nth-child(2) {
    
  }
}

.other-albums
{
  height: 90vh;
}

.video-container, .presse-container
{
  padding-top: 30px;
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 70px;
}

.presse-container img
{
  max-width: 300px;
  max-height: 250px;
  padding: 10px;
  margin: 10px;
}

.presse-container, .video-container
{
  min-height: 50vh;
}

/* Appliquer un style spécifique pour les écrans de téléphone portable (par exemple, jusqu'à 768px de large) */
@media screen and (max-width: 500px) {
  iframe {
    width: 100%; /* Redimensionner les vidéos pour qu'elles occupent 100% de la largeur de leur conteneur */
    height: auto; /* Ajuster automatiquement la hauteur en fonction de la largeur pour maintenir le rapport hauteur-largeur */
  }
}


.livret-container img
{
  max-height: 250px;
  width: 400px;
  max-width: 99%;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.634);
  cursor: pointer;
}

.livret-container
{
  padding: 30px;
}

/* Not Found */

.not-found-page
{
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
}

.not-found-page h1
{
  font-weight: 700;
}


.not-found-page p
{
  font-size: 18px;
  font-weight: 500;
}


@media (max-width: 550px) {
  .bouton-rectangle-container-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  }

  .bouton-rectangle-banner {
  
    width: 210px;
  

}

@media (max-width: 700px) {
.footer
{
  flex-direction: column-reverse;
}

}
@media (min-width: 992px) {
.logo-navbar-brand
{
  display: none!important;
}



/* .logo-rido
{
  display: none!important;
} */

}

@media (max-width: 991px) {

  .logo-rido-nav
  {
    display: none!important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    width: 192px;
    justify-content: start;
  }
  
  .navbar-expand-lg .navbar-collapse {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-nav
  {
    flex-direction: column;
  }

  .logo-links
  {
    flex-direction: row!important;
    justify-content: center!important;
    align-items: center!important;
  }
  .logo-links img
  {
margin : 0px 3px
  }
}


.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  max-width: 95%!important;
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
      max-width: 98%;
  }
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 95%;
}
}


@media (max-width: 400px) {
  .evenement-container {
    padding: 50px 10px;
  }
}


.loading {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #FEFBFE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fondu-out {
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.spinner {
  --uib-size: 40px;
  --uib-color: #161616;
  --uib-speed: .9s;
  --uib-center: calc(var(--uib-size) / 2 - var(--uib-size) / 5 / 2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: rotate calc(var(--uib-speed) * 3) linear infinite;
}

.dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  animation: oscillate var(--uib-speed) ease-in-out infinite alternate;
  transition: background-color 0.3s ease;
}

.dot:nth-child(1)::before {
  transform: translateX(var(--uib-center));
}

.dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot:nth-child(2)::before {
  transform: translateX(var(--uib-center));
  animation-delay: calc(var(--uib-speed) * -0.125);
}

.dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot:nth-child(3)::before {
  transform: translateX(var(--uib-center));
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot:nth-child(4)::before {
  transform: translateX(var(--uib-center));
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot:nth-child(5)::before {
  transform: translateX(var(--uib-center));
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot:nth-child(6)::before {
  transform: translateX(var(--uib-center));
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot:nth-child(7)::before {
  transform: translateX(var(--uib-center));
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot:nth-child(8)::before {
  transform: translateX(var(--uib-center));
  animation-delay: calc(var(--uib-speed) * -0.875);
}

@keyframes oscillate {
  0% {
      transform: translateX(var(--uib-center)) scale(0);
      opacity: 0.25;
  }

  100% {
      transform: translateX(0) scale(1);
      opacity: 1;
  }
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}


.image-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
  background-color: rgba(0, 0, 0, 0.816);
}

.image-modal{
  width: 100%!important; /* Assurez-vous que l'image remplit entièrement le container */
  height: auto!important; /* Assurez-vous que la hauteur de l'image est calculée automatiquement */
  display: block!important; /* Évitez les espaces supplémentaires autour de l'image */
  max-height: 99%!important;
  max-width: 99%!important;
}

.no-scroll {
  overflow: hidden;
}


.error {
  color: #b50606;
  font-weight: 500;
  letter-spacing: 1px;
}

.success {
  color: green;
  font-weight: 500;
  letter-spacing: 1px;
}

@media (max-width: 600px) {
  .music-info-container .col-1 {
    display: block;
  }
}

.image-modal-news
{
  width: 90%!important; /* Assurez-vous que l'image remplit entièrement le container */
  height: auto!important; /* Assurez-vous que la hauteur de l'image est calculée automatiquement */
  display: block!important; /* Évitez les espaces supplémentaires autour de l'image */
  max-height: 99%!important;
  max-width: 99%!important;
}